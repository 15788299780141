// courserl
.carousel-banner {
  /* .carousel-item img {
    height: auto;
    width: 100%;
  } */

  .carousel-indicators li {
    width: 20px;
    height: 20px;
    border: 2px solid $white;
    background-color: transparent;
    border-radius: 50%;
    transition: all 0.35s ease-in-out;
    margin-bottom: 0.25rem;
    position: relative;
  }

  .carousel-indicators li::before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: $white;
    transition: all 0.35s ease-in-out;
  }

  .carousel-indicators li.active {
    border: 2px solid $white;
  }

  .carousel-indicators li.active::before {
    // border-color: $primary ;
    background-color: $primary;
  }

  .carousel-caption {
    right: auto;
    // top: 50%;
    left: 5%;
    z-index: 1;
    color: #fff;
    text-align: center;
    // max-width: 285px;
  }

  .img-carousel {
    min-height: 500px;
  }
  .title-banner{
    text-shadow: -4px 3px 5px #000000;
  }

}

@media (min-width: 568px) {
  .carousel-banner {

    .title-banner{
      font-size: 3rem;
    }

    .carousel-caption {
      right: auto;
      top: 50%;
      left: 5%;
      z-index: 1;
      color: #fff;
      text-align: center;
      max-width: 285px;
    }

  }

}


@media (min-width: 768px) {
  .carousel-banner {

    .carousel-item img {
      height: auto;
      width: 100%;
      max-width: 100%;
    }

    .carousel-caption {
      // transform: translate(-50%, -50%)!important;

      right: auto;
      left: 20%;
      z-index: 10;
      color: #fff;
      text-align: center;
      z-index: 1;
      bottom: 20%;
      max-width: inherit!important;

    }


    /* float right carousel-indicators */
    .carousel-indicators {
      bottom: 10%;
    }

    .carousel-indicators {
      li {
        display: block !important;
      }
    }

    .img-carousel {
      min-height: 100vh;
    }


  }
}

// .carousel-thumbnails
.carousel-thumbnails .carousel-indicators {
  position: static;
  left: initial;
  width: initial;
  margin-right: initial;
  margin-left: initial;
  overflow-x: auto;
  white-space: nowrap
}

.carousel-thumbnails .carousel-indicators>li {
  width: initial;
  height: initial;
  text-indent: initial
}

.carousel-thumbnails .carousel-indicators>li .active img {
  opacity: 1;
}

/* Controles */
.carousel-thumbnails .carousel-control-prev,
.carousel-thumbnails .carousel-control-next {
  position: absolute;
  top: 45%;
  display: inline-block;
  width: auto
}



.carousel-thumbnails .carousel-control-prev,
.carousel-thumbnails .carousel-control-next {
  position: absolute;
  top: 40%;
  bottom: auto;
  background-color: $primary;
  opacity: 1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
}

.carousel-thumbnails .carousel-control-next {
  right: -15px;
}

.carousel-thumbnails .carousel-control-prev {
  left: -15px;
}

.carousel-thumbnails .carousel-inner {
  border: 2px solid #ffffff;
  padding: 5px;
}

.carousel-thumbnails .carousel-caption {
  position: absolute;
  right: 0;
  bottom: -25px;
  left: 0;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
  box-sizing: border-box;
  background: linear-gradient(rgba(130, 130, 130, 0.1), #000000);
}

@media (min-width: 768px) {
  .carousel-thumbnails .img-thumbnails {
    min-height: 350px;
  }

  .carousel-thumbnails .carousel-indicators>li img {
    width: 120px;
    height: 70px;
  }
}
