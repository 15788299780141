.form-theme {
    &.content {
        padding-top: 2rem;
        padding-bottom: 1.5rem;
    }

    .form-control {
        border-radius: 0;
        // background: transparent;
        // color: #f0f0ff;
        border: 1px solid #ccc;
        height: auto;
        padding: 0.5rem 0.75rem;
    }

    ::placeholder {
        // color: #f0f0ff;
        opacity: 1;
    }
}

// form contact
.form-contact .form-control-plaintext {
    border: solid #ffffff;
    border-width: 1px;
    border-radius: 2rem;
    padding-left: 1.25rem;
}

.form-contact .form-control-plaintext:focus {
    outline-color: transparent;
    color: #fff;
}

.hr-contact {
    border: 1px solid;
    width: 88%;
    margin-right: 0;
}

.control-rounded {
    border-radius: 2rem;
}
