.tooltips {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black;
  display: inline-block;

  .tiptext {
    visibility: hidden;
    width: auto;
    min-width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 6px 0.5rem;
    position: absolute;
    z-index: 1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  .tiptext::after {
    content: "";
    position: absolute;
    border-width: 10px;
    border-style: solid;
  }

  &.active .tiptext,
  &:hover .tiptext {
    visibility: visible;
  }
}



/* tooltips top */
.tooltips.tool-top .tiptext {
  // margin-left: -60px;
  top: -150%;
  left: 50%;
  transform: translateX(-50%);

}

.tooltips.tool-top .tiptext::after {
  // margin-left: -5px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);

  border-color: #2E2E2E transparent transparent transparent;
}

/* tooltips bottom */
.tooltips.tool-bottom .tiptext {
  // margin-left: -60px;
  top: 150%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltips.tool-bottom .tiptext::after {
  margin-left: -5px;
  bottom: 100%;
  left: 50%;
  border-color: transparent transparent #2E2E2E transparent;
}

/* tooltips left */
.tooltips.tool-left .tiptext {
  top: -5px;
  right: 110%;
}

.tooltips.tool-left .tiptext::after {
  // margin-top: -5px;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-color: transparent transparent transparent #2E2E2E;
}

/* tooltips right */
.tooltips.tool-right .tiptext {
  top: -5px;
  left: 110%;
}

.tooltips.tool-right .tiptext::after {
  // margin-top: -5px;
  right: 100%;
  top: 50%;
  transform: translateX(-50%);
  border-color: transparent #2E2E2E transparent transparent;
}



/* ======================================================================= */
.content-contact {
  position: absolute;
  top: 180px;
  color: #000;
  z-index: 4;

  padding: 0;
  margin: 0;
  list-style: none;

  left: 50%;
  transform: translateX(-50%);

  .tooltips {
    border: 1px solid $primary;
    padding: 0.65rem 0.85rem;
    border-radius: 50px;
    min-width: 230px;
    background-color: rgba($white, 0.7rem);
    cursor: pointer;
    transition: all .6s ease-in-out;

    .tool-text {
      color: $primary-light;
    }

    .icon-tool {
      max-width: 24px;
    }

    .tiptext {
      // background-color: rgba($primary-light, 0.75);
      border-radius: 0.5rem;
      border-color: 1px solid $primary-light;

      padding-left: 1.5rem;
      padding-right: 1.5rem;
      min-width: 250px;
    }

    &:hover {
      background-color: rgba($white, 0.95rem);

      .tool-text {
        color: $primary;
      }
    }
  }
}


/* ================== */

.tooltips.tool-contact .tiptext {
  top: -150%;
  left: 50%;
  transform: translateX(-50%);

  background-color: rgba($primary-light, 0.9);
}

.tooltips.tool-contact .tiptext::after {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-color: $primary-light transparent transparent transparent;
}

@media(min-width: 567.9px) {
  .content-contact {
    right: 0;
    left: auto;
    transform: none;
  }

  .tooltips.tool-contact .tiptext {
    top: -5px;
    right: 110%;
    left: auto;
    transform: none;

  }

  .tooltips.tool-contact .tiptext::after {
    // margin-top: -5px;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    border-color: transparent transparent transparent $primary-light;
  }
}

@media(min-width: 768px) {
  .content-contact {
    height: 100vh;
    // top: -100vh;
    top: 0;

    .list-contacts {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

  }
}