.powered-by {
  // position: absolute;
  right: 10px;
  color: rgba(255, 255, 255, 0.3);
  font-size: 14.5px;
  display: block;
}

.powered-by:hover {
  color: rgba(255, 255, 255, 0.85);
}

@media(min-width: 768px) {
  .powered-by {
    position: absolute;
    display: inline-block;
  }
}

