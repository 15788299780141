@each $color, $value in $theme-colors-additional {
  .text-#{$color} {
    color: $value !important;
  }
}

@each $color, $value in $theme-colors-additional {
  .bg-#{$color} {
    background-color: $value !important;
  }
}

