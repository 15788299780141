/*NIVEL INICIAL-PRIMARIO-SECUNDARIO*/
.imagen_p{
    text-align: center;
    margin: auto;
    max-width: 100%;
}
.contenido_i{
    box-shadow: 0 5px 15px rgba(0,0,0, .5);
    padding: 0px !important;
}
.contenido_i div.col-9{
    padding: 18px;
    background: #F6F6F6;
}
/*FIN NIVEL INICIAL-PRIMARIO-SECUNDARIO*/
