/*INDEX-SECTION*/
.app-1 div.bg-nav, .app-1 div.bg-nav div.navbar, .app-1 div.bg-nav div.navbar div.container{
    display: contents !important;
}
/**/

/*BIENVENIDA*/
.content-titulo{
    max-width: 100%;
    background: #0E3528;
    text-align: -webkit-center;
    .titulo-cole{
        text-align: left;
        padding-top: 30px;
        padding-bottom: 30px;
        span.texto{
            font-size: 35px;
            color: #FFFFFF;
            //font-family: 'Open Sans Extrabold';
            font-weight: bold;
            .barra {
                width: 10%;
                height: 5px;
                background: #FFFFFF;
            }
        }
    }
}
@media (max-width: 414px){
    div.content_video iframe{
        width: 350px;
        height: 200px;
    }
}
@media (max-width: 320px){
    div.content_video iframe{
        width: 300px;
        height: 170px;
    }
}
/*FIN BIENVENIDA*/

/*HISTORIA*/
.texto_his:before{
    content: "";
    display:inline-block ;
    width: .7rem;
    height: .7rem;
    border-radius: 50%;
    margin-right: 0.25rem;
    background-color: red ;
}
/*FIN HISTORIA*/

/*VISION-MISION*/
.m-v{
    max-width: 100%;
    display: flex !important;
}
.borde{
    border-bottom: 2px solid #0E3528;
}
.titulo_mv{
    background: #0E3528;
    font-size: 35px;
    color: #FFFFFF;
    font-family: 'Open Sans Extrabold';
    font-weight: bold;
    max-width: 50%;
}
.imagen_mv{
    margin: 5px;
    img{
        width: 530px;
        height: 300px;
    }
}

.container div.contenido_mv{
    margin: 20px;
    text-align: left;
}
.container div.contenido_mv div.titulo_mv{
    text-align: center;
    max-width: 40%;
}
.container div.contenido_valores{
    text-align: -webkit-center;
}
.container div.contenido_valores div.titulo_valores{
    background: #0E3528;
    font-size: 35px;
    color: #FFFFFF;
    font-family: 'Open Sans Extrabold';
    font-weight: bold;
    max-width: 40%;
    text-align: center;
}
@media (max-width: 736px){
    .m-v{
        display: block !important;
    }

}
@media (max-width: 400px){
    .imagen_mv img{
        height: 210px;
    }
}
/*FIN VISION-MISION*/

/*ELEMENTOS-INSTITUCIONALES*/
.content_separador{
    border-right: 2px solid #0E3528;
}
@media (max-width: 812px){
    .content_separador{
        border-right: 0;
        border-bottom: 2px solid #0E3528;
    }
}
.titulo_e:before{
    content: "";
    display:inline-block ;
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50%;
    margin-right: 0.25rem;
    background-color: red ;
}
.imagen_e{
    margin: auto;
    max-width: 80%;
}
/*FIN ELEMENTOS-INSTITUCIONALES*/
