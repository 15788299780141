/*PSICOLOGIA*/
.cont-psicologia{
    display: flex;
    justify-content: center;
}
.cont-psicologia .bien{
    display: flex;
}
.cont-psicologia, .cont-psicologia .bien div img{
    max-height: 100px;
}
.cont-psicologia .bien div{
    padding: 0px;
    margin: auto;
}
.texto-sub {
    font-size: 1.25rem;
    font-family: 'Open Sans Extrabold';
    font-weight: bold;
    color: #0E3528;
}
@media (max-width: 736px){
    .cont-psicologia{
        display: block;
        max-height: 100%;
    }
    .cont-psicologia .bien{
        max-width: 100%;
    }
}
/*FIN PSICOLOGIA*/
/*ARTE-TEATRO*/
section.arte .imagen_p iframe{
    max-width: inherit;
}
.cont-arte{
    display: flex;
    justify-content: space-around;
    padding-top: 30px;
    padding-bottom: 30px;
}
.fotos_arte{
    max-width: 200px;
    max-height: 200px;
    border: 3px solid #0E3528;
}
@media (max-width: 736px){
    .cont-arte{
        display: block;
        padding: 20px;
    }
    .fotos_arte{
        max-width: 100%;
        max-height: 100%;
        text-align: center;
        margin: 15px;
    }
}
/*FIN ARTE-TEATRO*/
/*COMEDOR*/
.cont-comedor{
    display: flex;
    justify-content: space-around;
    padding-top: 30px;
    padding-bottom: 30px;
}
.cuadro_comedor{
    display: flex;
    padding: 0px;
    max-width: 350px;
    max-height: 100px;
    border: 2px solid #0E3528;
}
.cuadro_comedor div{
    margin: auto;
    padding: inherit;
}
.cuadro_comedor div p{
    margin: auto;
}
.title_comedor{
    font-family: 'Open Sans Extrabold';
    font-weight: bold;
    font-size: 12px;
    padding-left: 10px;
}
.sub_comedor{
    font-family: 'Open Sans Semibold';
    font-weight: bold;
    font-size: 20px;
    padding-left: 10px;
}
@media (max-width: 736px){
    .cont-comedor{
        display: block;
        text-align: -webkit-center;
    }
}
/*FIN COMEDOR*/
