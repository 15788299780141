/*NAVBAR*/
#navbarSupportedContent ul.social{
    margin-left: auto !important;
}
/*FIN NAVBAR*/

/*INICIO*/
%formato_contactanos {
  font-weight: 100;
  transition: 0.5s;
  letter-spacing: 1px;
  display: block;
}

@media (max-width: 1920px) {
  div.secciones article{
    margin-top: 210px;
  }
}
@media (max-width: 1366px) {
  div.secciones article{
    margin-top: 165px;
  }
}
@media (max-width: 1080px) {
  div.secciones article{
    margin-top: 110px;
  }
}
/*FIN INICIO*/

/*COMUNICADOS*/
%centrado {
  max-width: 100%;
  margin: auto;
}

%centrar_lista {
  margin: auto;
  width: 100%;
  list-style: none;
  display: flex;
}

.tabs {
  @extend %centrado;
  background: white;
  font-family: 'Open Sans Extrabold';
}

.banner-com {
  @extend %centrado;
  text-align: center;
  max-width: 80%;
}

.wrap-content {
  @extend %centrado;
  background: #0E3528;
  height: 230px;
}

.wrap {
  max-width: 85%;
  margin: auto;
}

ul.comunicados-tabs {
  @extend %centrar_lista;
  background: #0E3528;
}

ul.comunicados-tabs li {
  width: 30%;
  margin: auto;
  text-align: center;
}

.circulo {
  width: 70px;
  height: 70px;
  border-radius: 50% !important;
  margin: auto;
  max-width: 100%;
}

ul.comunicados-tabs li a {
  color: white;
  text-decoration: none;
  font-size: 20px;
  text-align: center;
  display: block;
  padding: 50px 0px;
  font-weight: bold;
}

.active-tabs {
  span {
    color: #ECC416;
  }
  background: #06261A;
}

.section-title-divider-c {
  justify-content: center;
  width: 30%;
  height: 3px;
  background: #ECC416;
  margin: auto;
  margin-bottom: 20px;
}

.secciones {
  margin: auto;
  text-align: center;
  width: 85%;
}

.comunicado {
  background: #EDEDED;
  margin: 15px;
}

.selector {
  background: #FFFFFF;
  padding: 3px;
  margin: 15px;
  border: 1px solid #FFFFFF;
  transition: all 250ms ease-out;
  &:hover {
    border: 1px solid;
  }
}

%formato_fecha {
  text-align: left;
  font-family: 'Open Sans Extrabold';
  font-size: 12px;
  color: white;
  padding-top: 1px;
  padding-left: 35px;
}

.fecha_c {
  @extend %formato_fecha;
  background: #0E3528;
}

.fecha_n {
  display: flex;
}

.date_c {
  @extend %formato_fecha;
  background: #BF0D14;
  height: max-content;
  width: 80%;
  margin-bottom: 14px;
}

.date_c p {
  height: 3px;
}

.new_c {
  @extend %formato_fecha;
  background: #ECC416;
  padding-left: 0px;
  height: max-content;
  width: 20%;
  text-align: center;
  margin-bottom: 14px;
}

.new_c p {
  height: 3px;
}

.enlace_c {
  text-align: right;
  background: #FFFFFF;
}

.secciones article {
  padding: 30px;
  text-align: center;
}

.tabs-1 {
  display: none;
}

@media screen and (max-width: 1080px) {
  .fecha_n {
    background: #BF0D14;
  }
  .new_c {
    margin: auto;
  }
  .new_c p {
    height: 100%;
  }
}

@media screen and (max-width: 812px) {
  .banner-com {
    display: none;
  }
  .wrap {
    max-width: 100%;
  }
  .wrap-content {
    height: none;
  }
  ul.comunicados-tabs {
    padding: 0px;
    display: grid;
  }
  ul.comunicados-tabs li {
    width: none;
    flex-basis: 0;
    flex-grow: 1;
    display: contents;
  }
  ul.comunicados-tabs li a {
    padding: 20px 0px;
  }
  .tabs {
    display: none;
  }
  .tabs-1 {
    display: block;
  }
  //Cuadro Nuevo
  .date_c {
    margin: auto;
  }
  .new_c {
    width: auto;
  }
}

@media screen and (max-width: 414px) {
  .fecha_c {
    padding: inherit;
  }
  .date_c p {
    padding-right: 10%;
  }
}

@media screen and (max-width: 320px) {
  .date_c {
    height: 40px;
  }
}
/*FIN COMUNICADOS*/

/*COMUNICADOS-MOVIL*/
.tabs-1 {
  @extend %centrado;
  font-family: 'Open Sans Extrabold';
}

#acordeon li {
  list-style: none;
}

.item_movil_1,
.item_movil_2,
.item_movil_3 {
  background: #0E3528;
}

.item_movil_1 a,
.item_movil_2 a,
.item_movil_3 a {
  color: white;
  text-decoration: none;
  font-size: 20px;
  text-align: center;
  display: block;
  font-weight: bold;
  padding: 10px;
}

.item_movil_1 div.tabla,
.item_movil_2 div.tabla,
.item_movil_3 div.tabla {
  background: #FFFFFF;
}

#acordeon li div {
  display: none;
  text-align: center;
}

#acordeon li:target div {
  display: block;
}

.enlace_m {
  text-align: right;
  background: #FFFFFF;
  a {
    font-size: 15px;
    color: #0E3528;
  }
}

div table.eventos_m a {
  color: #0E3528;
}

.section-title-divider-c-m {
  justify-content: center;
  width: 30%;
  height: 3px;
  background: #ECC416 !important;
  margin: auto;
  margin-bottom: 20px;
}
/*FIN COMUNICADOS-MOVIL*/

/*ELEGIRNOS*/
%titulos {
  text-align: left;
  font-family: 'Open Sans Extrabold';
}

%radio_circulo {
  width: 180px;
  height: 180px;
  border-radius: 50% !important;
  background: white;
  margin: auto;
  max-width: 100%;
}

.elegirnos {
  background: white;
  max-width: 100%;
}

.titulo {
  max-width: 75%;
}

.titulo h2 {
  @extend %titulos;
  font-weight: bold;
  font-size: 35px;
  color: #0E3528;
}

.titulo h4 {
  @extend %titulos;
  font-size: 25px;
  color: #575756;
}

.section-title-divider {
  width: 130px;
  height: 7px;
  background: #0E3528;
  margin: 0 left;
  margin-bottom: 20px;
}

.carrucel {
  background: #ECC416;
}

//CAROUSEL (Elerginos y foros)
.elementos_carrucel {
  margin: auto;
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

div.titulos div.item_foro {
  @extend %centrar_lista;
  // max-width: 320px;
  margin-right: 0;
}
@media(min-width: 568px)
{
  div.titulos div.item_foro {
     max-width: 320px;
  }
}

//
.radius-circle {
  @extend %radio_circulo;
  padding: 20px 0px;
}

.infraestructura {
  @extend %radio_circulo;
}

@media (max-width: 812px) {
  .elementos_carrucel {
    display: list-item;
  }
}

/*FIN ELEGIRNOS*/

/*NOVEDADES*/
%item {
  margin: 5%;
  max-width: 450px;
  max-height: 300px;
}

.novedades {
  @extend %centrado;
  background: white;
}

.novedad {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
}

.item_n {
  @extend %item;
  background: #009FE3;
}

.enlaces a:link,
.enlaces a:visited,
.enlaces a:active {
  text-decoration: none;
}

.ver_mas {
  width: 20%;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.ver_mas a {
  font-weight: 100;
  font-size: 20px;
  letter-spacing: 1px;
  display: block;
  padding: 8px 20px;
  border-radius: 2px;
  transition: 0.5s;
  margin: 12px;
  font-family: 'Open Sans Semibold';
}

.btn_formato {
  border: 2px solid #919191;
  color: #919191;
  &:hover {
    border: 2px solid;
  }
}

@media (max-width: 812px) {
  .novedad {
    display: inline-grid;
  }
  .ver_mas {
    width: 40%;
  }
}

@media (max-width: 414px) {
  .ver_mas {
    width: 70%;
  }
}

//Novedades-Actividades
.menu_actividades{
    background: #0E3528;
}
.articulo_actividades{
    margin-top: 0 !important;
}
//Fin
/*FIN NOVEDADES*/

/*CONVENIOS*/
.convenios {
  @extend %centrado;
  background: white;
}

div.titulos {
  @extend %centrar_lista;
  justify-content: center;
}

div.titulos div.item {
  @extend %centrar_lista;
  max-width: 320px;
}

div.titulos div.item img {
  max-width: 320px;
}

@media (max-width: 812px) {
  div.titulos {
    display: inline-grid;
  }
  div.titulos div.item img {
    max-width: 400px;
  }
}
@media (max-width: 480px) {
  div.titulos div.item img {
    max-width: 320px;
  }
}

@media (max-width: 414px) {
  div.titulos div.item img {
    max-width: 268px;
  }
}
/*FIN CONVENIOS*/

/*FOROS*/
.foros {
  @extend %centrado;
}

.carrucel_1 {
  background: #FFFFFF;
}

.caja_img {
  max-width: 300px;
  max-height: 200px;
  position: absolute;
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
  top: -12px;
}

.caja_in_img {
  background: #ECC416;
  span {
    font-family: 'Open Sans Bold';
    font-size: 15px;
    color: #000000;
    justify-content: center;
  }
}

.item_f {
  @extend %item;
  background: #FFFFFF;
  margin-top: 10px;
  margin-bottom: 10px;
}

div.mapa {
  max-width: 100%;
  text-align: center !important;
  iframe {
    max-width: 100%;
  }
}

.carousel-foro .carousel-control-prev,
.carousel-foro .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: auto;
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: auto;
  color: #b2b2b2;
  text-align: center;
  opacity: 0.75;
  transition: opacity 0.15s ease;
  border: 1px solid #0e3528;
  padding: 0.25rem;
}

.carousel-foro .carousel-control-prev {
  left: auto;
  right: 35px;
}

.carousel-foro .carousel-control-prev:hover,
.carousel-foro .carousel-control-next:hover {
  color: #0e3528;
  border: 1px solid #0e3528;
}

section.foros div.container div.item_foro img{
    max-width: 280px;
}

@media (max-width: 812px) {
  div.titulos div.item_foro {
    max-width: 650px;
    max-width: none;
  }
  div.titulos {
    display: inline-grid;
  }
  section.foros {
    margin-left: 60px;
  }
}

@media(min-width: 768px) {
  .caja_img {
    width: 90% !important;
  }
}
@media (max-width: 414px) {
  section.foros {
    margin-left: 0px;
  }
}
/*FIN FOROS*/

/*FOOTER*/
%texto {
  font-size: 1rem;
  font-family: 'Open Sans Extrabold';
}

.footer,
.footer_b {
  background: #111111;
}

.row_f {
  text-align: center;
}

.item_footer {
  @extend %centrado;
  margin-top: 0;
  display: block;
}

.item_footer ul.footer_r {
  max-width: 100%;
  display: inline-block;
}

.item_footer p,
.footer_bottom {
  @extend %texto;
  margin: auto;
  // text-align: center;
  font-weight: bold;
}

.item_footer ul {
  list-style: none;
  padding: 0;
  margin-left: 10px;
}

.item_footer ul li:before {
  @extend %texto;
  color: #878787;
}

.item_footer ul li a {
  @extend %texto;
  color: #878787;
}

.item_footer ul.footer_datos {
  list-style: none;
  @extend %texto;
  color: #878787;
}

@media(max-width: 1024px) {
  div.d-flex-f {
    display: block;
  }
  .item_footer ul li a {
    font-size: 13px;
  }
  .item_footer ul.footer_datos,
  .footer_bottom,
  .item_footer p {
    font-size: 14px;
  }
}

@media(max-width: 768px) {
  .item_footer ul li a {
    font-size: 10px;
  }
  .item_footer ul.footer_datos,
  .footer_bottom,
  .item_footer p {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .a, .be, .c, .d {
    display: none;
  }
}
/*FIN FOOTER*/

