//  ---------------------------------------------------
//     NAVBAR STYLE
// -----------------------------------------------------
.navbar-theme {


  .navbar-collapse {
    height: inherit;
  }

  .nav-item,
  .form-inline {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #ffffff !important;
    text-decoration: none;
    background-color: #0e3528;
  }

  .nav-link.active,
  .nav-link.dropdown-toggle.active {
    color: darken($primary, 1%)
  }


  .navbar-toggler {
    padding: 0.25rem 0.25rem;
    margin-right: 0.5rem;
  }

  .dropdown-menu {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.175);
    padding: .5rem 0;

    .dropdown-item i {
      margin-right: 0.25rem;
    }
  }

  .dropdown-menu.dropdown-right {
    right: 0;
    left: auto;
  }

}

// navbar-dark
/* .navbar-dark {
  .navbar-collapse {
    @media (max-width: 767.98px) {
      border-top: 1px solid rgba($white, 0.05);
    }
  }

  i {
    color: $navbar-dark-active-color;
  }

  .navbar-brand a {
    color: $navbar-dark-active-color;
  }

  .dropdown-menu {
    i {
      color: color-yiq($navbar-dark-active-color);
    }
  }
} */

// navbar-light
.navbar-primary {
  .navbar-brand {
    color: $navbar-light-brand-color;

    @include hover-focus() {
      color: $navbar-light-brand-hover-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-light-color;

      @include hover-focus() {
        color: $navbar-light-hover-color;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }

    .show>.nav-link,
    .active>.nav-link,
    .nav-link.show,
    .nav-link.active {
      color: darker($primary, 10%) !important;
    }
  }

  .navbar-toggler {
    color: $navbar-light-color;
    border-color: $navbar-light-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: escape-svg($navbar-light-toggler-icon-bg);
  }

  .navbar-text {
    color: $navbar-light-color;

    a {
      color: $navbar-light-active-color;

      @include hover-focus() {
        color: $navbar-light-active-color;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .navbar-theme {

    .nav-item,
    .form-inline {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .navbar-nav {
      //height: 64px;

      .nav-item {
        height: 100%;

        .nav-link {
          height: 100%;
          // width: 115px;
          position: relative;
          padding-right: 0.5rem;
          font-size: 0.95rem;
          font-weight: 900;
          text-transform: uppercase;

          &.dropdown-toggle::after {
            position: absolute;
            top: 1rem;
            right: -0.25rem;
          }
        }
      }

    }



  }
}


@include media-breakpoint-down(md) {
  .navbar-theme {
    .navbar-brand {
      .logo-brand {
        max-height: 50px;
      }
    }
  }
}


.bg-nav {
  background: rgba(255, 255, 255, 1);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(252, 252, 252, 0.68) 43%, rgba(255, 255, 255, 0.01) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(43%, rgba(252, 252, 252, 0.68)), color-stop(100%, rgba(255, 255, 255, 0.01)));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(252, 252, 252, 0.78) 53%, rgba(255, 255, 255, 0.01) 100%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(252, 252, 252, 0.78) 53%, rgba(255, 255, 255, 0.01) 100%);
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(252, 252, 252, 0.78) 53%, rgba(255, 255, 255, 0.01) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(252, 252, 252, 0.68) 43%, rgba(255, 255, 255, 0.01) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);

  // position: absolute;
  position: relative;
  width: 100%;
  box-shadow: none !important;
  z-index: 9;
}
