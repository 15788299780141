//  ---------------------------------------------------
//     NAVBAR STYLE
// -----------------------------------------------------
.navbar {

  .nav-item {
    font-size: 1.15rem !important;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  // navbar-brand

  @include media-breakpoint-up(md) {
    .logo-brand {
      // width: 195px;
    }
  }

  @media (max-width: 575.98px) {
    .logo-brand {
      padding: .5rem;
    }
  }

}


//  ---------------------------------------------------
//     HEADER STYLE
// -----------------------------------------------------

@media (max-width: 767.9899px) {


  .brand-md {
    height: inherit;
    display: flex;
    align-items: center;
    width: 100%;

    .navbar-brand {
      height: 100%;
      overflow: hidden;
      padding: 0.2rem .25rem;

      .logo-brand {
        max-height: 75px;
        height: 100%;
        transition: all .5s ease-in;
        // padding-bottom: .5rem;
      }

    }

    .social {
      display: inline-block;
    }

  }
}

/* ============================== */
/* .navbar-hover .show ul .dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
}

@media only screen and (min-width: 768px) {


  .navbar-hover .collapse ul li {
    position: relative;
  }

  .navbar-hover .collapse ul.submenu li:hover>ul {
    display: block
  }

  .navbar-hover .collapse ul.submenu ul {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 250px;
    display: none
  }

  .navbar-hover .collapse ul.submenu ul ul {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 250px;
    display: none
  }

}   */

// MENUS VERSION 03 ======================================
.navbar-nav .dropdown-menu
{
  text-transform: none;
}
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  // transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: .8em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: .1rem;
  margin-right: .1rem;

  &.show {
    box-shadow: none;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
  }

}


@include media-breakpoint-up(md) {
  .dropdown-submenu a::after {
    transform: rotate(-90deg);
    // position: absolute;
    // right: 6px;
    // top: .8em;
  }
}

@media (max-width: 812px) {
  .posicion_icono{
    width: 100%;
  }
}
