/* fill social */
.fill-facebook,
.fill-facebook svg {
  fill: #0053b7;
}

.fill-facebook:hover,
.fill-facebook svg:hover {
  fill: #004393;
}


.fill-whatsapp,
.fill-whatsapp svg {
  fill: #53d026;
}

.fill-whatsapp:hover,
.fill-whatsapp svg:hover {
  fill: #4ca92b;
}

// list social
ul.social {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
  // display: flex;

}

ul.social li {
  display: inline-block;
  margin-right: .35rem;


}

ul.social li a {
  text-decoration: none !important;
  line-height: normal;
  color: $primary;
  .img-social {
    width: 24px;
  }
}

.social li a:hover {
  transition: all 0.8s;
  color: $white !important;
  .img-social {
    transform: scale(1.15);
  }
}


// White
.social-white li a {
  color: $white;
}

.social-white li a:hover {
  color: rgba(#000000, .5) !important;
}

/* Dark */
.social-dark li a {
  color: $dark;
}

.social-dark li a:hover {
  color: lighten($dark, 5%) !important;
}

.social a.icon-circle,
.social a .icon-circle {
  border-radius: 50%;
  background: #b2b2b2;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dark;
  font-size: 1em;
  padding: 0.25rem;
}

.social a.icon-circle:hover,
.social a:hover .icon-circle {
  background: $primary !important;
  color: $white;
}

// ==============
.list-top {

  font-size: 0.9rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  text-align: left;

  li a {
    color: #0e3528;
  }

  li a:hover {
    color: darken(#0e3528, 10%);
  }
}

@include media-breakpoint-up(sm) {
  .list-top {
    text-align: center;
  }
}


@include media-breakpoint-up(md) {
  .list-top {
    font-size: 1rem;
    z-index: 10;
    top: 4px;
    // text-align: right;

    // position: absolute;
    // right: 0;

    // li a {
    //   // color: #0e3528;
    // }

    // li a:hover {
    //   // color: darken(#0e3528, 10%);
    // }

  }
}

@include media-breakpoint-up(lg) {
  .list-top {
    // font-size: 1rem;
    text-align: right;

    position: absolute;
    right: 0;
    // z-index: 10;
    // top: 4px;


  }
}