.list-footer{
  li{
    margin-bottom: 0.5rem ;

    i{
      padding-right: 1rem;
    }

  }
}

.footer .copy{
  background-color: rgba(117, 117, 117, 0.09);
  padding-top: .5rem;
  padding-bottom: .5rem;
}